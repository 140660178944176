import Card from "../../components/card/Card";

export default function Cards({ cities, onSubmit }) {
  return (
    <div className="cards">
      {cities.map((city, index) => {
        return (
          <Card key={index} name={city.name} citycode={city._id} onSubmit={() => onSubmit(city)} />
        );
      })}
    </div>
  );
}
