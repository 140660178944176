const staticDatas = {
	score: {
		title: "Score globale",
		description: "",
	},
	infomation: {
		title: "Acces à l'information",
		description: "Identifier  des  territoires  mal  couverts  par  une  offre  de  service  d'information  ou  des populations qui auront des difficultés à comprendre l'information.",
	},
	interfaces: {
		title: "Acces au interfaces numériques",
		description: "Identifier des territoires mal couverts par les réseaux ou dans lesquels des populations auront des difficultés financières à y accéder.",
	},
	digital: {
		title: "Compétences numériques",
		description: "Identifier  des  populations  parmi  lesquelles  s'observe  une fréquence d'illectronisme ou difficulté à utiliser internet.",
	},
	administrative: {
		title: "Compétences administratives",
		description: "Identifier  des  populations  parmi  lesquelles  s'observent  des  difficultés  à accomplir des procédures administratives.",
	},
};

export { staticDatas };
