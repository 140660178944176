import React from "react";
import SectionStats from "./section-stats/SectionStats";
import "./Stats.css";

function Stats({ data }) {
	return (
		<div className="container-stats">
			<div className="container-cards">
				<SectionStats typeInfo="score" data={data} />
				<SectionStats typeInfo="infomation" data={data} />
				<SectionStats typeInfo="interfaces" data={data} />
				<SectionStats typeInfo="digital" data={data} />
				<SectionStats typeInfo="administrative" data={data} />
			</div>
		</div>
	);
}

export default Stats;
