import { useState, useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import { debounce } from "lodash";
import "./App.css";
import CityService from "./services/city-service";
import SearchBar from "./components/search-bar/SearchBar";
import Loader from "./components/loader/Loader";
import Cards from "./containers/cards/Cards";
import Stats from "./containers/stats/Stats";

const delayedGetCities = debounce((setCities, search, setLoading) => {
	if (search.trim().length > 1) {
		const cityService = new CityService();
		cityService.all(search).then((res) => {
			setCities(res);
			setLoading(false);
		});
	} else {
		setLoading(false);
		setCities([]);
	}
}, 700);

function App() {
	const [search, setSearch] = useState("");
	const [cities, setCities] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingCity, setLoadingCity] = useState(false);
	const [city, setCity] = useState();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

	const handleChange = (event) => {
		let searching = event.target.value;
		setSearch(searching);
		setLoading(true);
		delayedGetCities(setCities, searching, setLoading);
	};

	const onSubmit = (currentCity) => {
		if (city && city._id === currentCity._id) {
			return;
		}
		setLoadingCity(true);
		setSearch(currentCity.name);
		const cityService = new CityService();
		cityService
			.get(currentCity._id)
			.then((res) => {
				setCity(res);
				setLoadingCity(false);
			})
			.catch(() => {
				setLoadingCity(false);
			});
	};

	return (
		<div className="App">
			<SearchBar
				handleChange={handleChange}
				loading={loading}
				search={search}
			/>
			<Cards cities={cities} onSubmit={onSubmit} />
			<div className="city-stats" ref={componentRef}>
				{!loadingCity && city ? (
					<Stats data={city} />
				) : loadingCity ? (
					<Loader />
				) : (
					<></>
				)}
			</div>
      {city && <a onClick={handlePrint} className="btn-print">Télécharger les données</a>}
		</div>
	);
}

export default App;
