import React from "react";
import "./SectionStats.css";
import CardStats from "../../../components/cards-stats/CardStats";
import { staticDatas } from "../../../datas/static-datas";

function SectionStats({ typeInfo, data }) {
	return (
		<div className="section-stats">
			<div className="title-section">
				<h2>{staticDatas[typeInfo].title}</h2>
			</div>
			<div className="description-section">
				<p>{staticDatas[typeInfo].description}</p>
			</div>
			<div className="container-cards">
				<div className="cards-content">
					<CardStats data={data} target="dep" typeInfo={typeInfo} />
					<CardStats data={data} size="large" typeInfo={typeInfo} />
					<CardStats data={data} target="reg" typeInfo={typeInfo} />
				</div>
			</div>
		</div>
	);
}

export default SectionStats;
