import Loader from "../../components/loader/Loader";

export default function SearchBar({ handleChange, loading, search }) {
  return (
    <div className="search-content">
      <input
        className="input-search"
        onChange={handleChange}
        value={search}
        placeholder="Rechercher une ville..."
      ></input>
      {loading && (
        <div className="loader">
          <Loader />
        </div>
      )}
    </div>
  );
}
