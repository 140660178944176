import axios from "axios";

export default class CityService {
  all(search) {
    return axios
      .get(`http://green-api.ncastro.fr/search?q=${search}`)
      .then(({ data }) => data);
  }

  get(id) {
    return axios.get(`http://green-api.ncastro.fr/city/${id}`).then(({ data }) => data);
  }
}
