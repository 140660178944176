import React from "react";
import "./CardStats.css";

export default function CardStats({
	size = "small",
	data,
	target = "",
	typeInfo,
}) {
	const cityScore = data[typeInfo];
	const mainScore = target !== "" ? data[`${target}_${typeInfo}`] : cityScore;
	const diffScore = cityScore - mainScore;
	const cityIsBetter = cityScore > mainScore;

	const getName = () => {
		switch (target) {
			case "dep":
				return data.department;
			case "reg":
				return data.region;
			default:
				return data.name;
		}
	};

	return (
		<div className={`card-stats ${size}`}>
			<div className={`card-content-stats`}>
				{size === "small" && (
					<div className={`diff-score ${cityIsBetter ? "up" : "down"}`}>
						<span>{diffScore.toFixed(1)} %</span>
					</div>
				)}
				<span className="score">{mainScore.toFixed(1).replace(".0", "")}</span>
				<span className="city">{getName()}</span>
			</div>
		</div>
	);
}
